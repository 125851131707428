import React, { lazy, Suspense, useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Route, Routes } from 'react-router-dom';

import SsrRedirect from '../../SsrRedirect';
import { Footer } from '../../blocks/Footer';
import { Header } from '../../blocks/Header';
import NotFoundPage from '../../blocks/NotFoundPage/NotFoundPage';
import { Loader } from '../../components/Loader';
import RequireAuth from '../../components/RequireAuth';
import { DEFAULT_DISCOVER_URL } from '../../const';
import { useWithoutNavigation } from '../../context/OnlineContentConnectorContext';
import { ThemeCtx } from '../../context/ThemeProvider/ThemeProvider';
import { GET_COURSES_EXIST_INFO, GET_COURSES_PROGRESS_INFO } from '../../graphql/courses';
import { GET_HAS_NEWS } from '../../graphql/local';
import { GET_NAVIGATION } from '../../graphql/navigation';
import useIsSsku from '../../hooks/tenant/useIsSsku';
import useAuth from '../../hooks/useAuth';
import useVerse from '../../hooks/useVerse';
import Modal from '../../modals/Modal';
import { AssignedLearningPage } from '../../pages/AssignedLearningPage';
import AslSharePage from '../../pages/AssignedLearningPage/pages/AslSharePage';
import { Homepage } from '../../pages/Homepage';
import { LoginPage } from '../../pages/LoginPage';
import { LogoutPage } from '../../pages/LogoutPage';
import ContinueWatching from '../../pages/PathwayPage/components/ContinueWatching';
import { RegistrationPage } from '../../pages/RegistrationPage';
import { ResetPasswordPage } from '../../pages/ResetPasswordPage';
import { SsoLoginPage } from '../../pages/SsoLoginPage';
import {
  CASE_STUDY_PATH,
  DiscoverRoutes,
  hardcodedCourseUrls,
  homepageRoutes,
  loginRoutes,
  oldExpertPageRoutes,
  SHARE_COURSE_ROUTE,
  SHARE_PATHWAY_ROUTE,
} from '../../routes';
import styled from '../../styled';
import {
  CourseStatus,
  LearnerInfo,
  Maybe,
  NavigationType,
  Query,
  QueryGetNavigationArgs,
  Tenant,
} from '../../types';
import { getHeaderLogoSsku, TenantsRefs } from '../../utils/tenantsConfig';
import { getUserComUserId } from '../../utils/userComUtils';
import { CustomPageLayout } from '../CustomPageLayout';

const AnalyticsPage = lazy(() => import('../../pages/AnalyticsPage/AnalyticsPage'));
const DashboardPage = lazy(() => import('../../pages/DashboardPage/DashboardPage'));
const NewsPage = lazy(() => import('../../components/NewsItem/NewsPage'));
const NewsList = lazy(() => import('../../components/NewsList/NewsList'));
const TestPage = lazy(() => import('../../pages/TestPage/TestPage'));
const ThankYouPage = lazy(() => import('../../pages/ThankYouPage/ThankYouPage'));
const CourseDetailsPage = lazy(
  () => import('../../pages/courses/pages/CourseDetailsPage/CourseDetailsPage'),
);
const CourseDetailsPageHardcoded = lazy(
  () => import('../../pages/courses/pages/CourseDetailsPageHardcoded/CourseDetailsPageHardcoded'),
);
const CoursesListPage = lazy(
  () => import('../../pages/courses/pages/CourseListPage/CoursesListPage'),
);
const CourseProgress = lazy(
  () => import('../../pages/courses/pages/CourseProgress/CourseProgress'),
);
const CourseSharePage = lazy(
  () => import('../../pages/courses/pages/CourseSharePage/CourseSharePage'),
);
const MyAccountPage = lazy(() => import('../../pages/MyAccountPage/MyAccountPage'));
const PathwayPage = lazy(() => import('../../pages/PathwayPage/PathwayPage'));
const PricePage = lazy(() => import('../../pages/PricePage/PricePage'));
const SharePage = lazy(() => import('../../pages/SharePage/SharePage'));
const SingleVideoPage = lazy(() => import('../../pages/SingleVideoPage/SingleVideoPage'));
const DiscoverPage = lazy(() => import('../../pages/DiscoverPage/DiscoverPage'));
const CaseStudyPage = lazy(() => import('../../pages/CaseStudyPage/CaseStudyPage'));
const CertificatePage = lazy(() => import('../../pages/CertificatePage/CertificatePage'));

interface RXDLayoutProps {
  tenant?: Maybe<Tenant>;
  userInfo?: LearnerInfo;
  userLoading: boolean;
  authAppId?: string;
  waitForUser?: boolean;
  loadingTenant: boolean;
}

export type CourseDropdownItem = {
  id?: string;
  name?: string | null;
  navigationDescription?: string | null;
  slug?: string;
  isStarted: boolean;
};

export const Layout: React.FC<RXDLayoutProps> = ({
  tenant,
  loadingTenant,
  userInfo,
  userLoading,
  authAppId,
  waitForUser,
}) => {
  const [isHeaderHidden, setIsHeaderHidden] = useState<boolean>(false);
  const { changeUserId } = useVerse();
  const isSsku = useIsSsku();
  const withoutNavigation = useWithoutNavigation();
  const { mode } = useContext(ThemeCtx);
  const [logoUrl, setLogoUrl] = useState(tenant?.logo_url);

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    setLogoUrl(
      isSsku
        ? getHeaderLogoSsku(mode)
        : userInfo?.company?.logo_url
        ? userInfo?.company?.logo_url
        : tenant?.logo_url,
    );
  }, [userInfo?.company?.logo_url, tenant?.logo_url, isSsku, mode]);

  const tenantTitle = tenant?.title || '';
  const tenantName = tenant?.name;
  const tenantId = tenant?.id;
  const tenantCircleUrl = tenant?.circle_image_url;
  const linkedInId = tenant?.linkedin_id;
  const assignedLearningsName = userInfo?.company?.short_name || '';

  const { data } = useQuery<Pick<Query, 'hasNews'>>(GET_HAS_NEWS);
  const hasNews = data?.hasNews;
  const footerPartners = tenant?.footer_partners;

  const footerNavigationType = isAuthenticated
    ? NavigationType.FooterLoggedIn
    : NavigationType.FooterLoggedOut;
  const { data: navigationData } = useQuery<Pick<Query, 'getNavigation'>, QueryGetNavigationArgs>(
    GET_NAVIGATION,
    {
      variables: {
        filter: {
          navigation_type: isAuthenticated
            ? [footerNavigationType]
            : [NavigationType.LogedOut, footerNavigationType],
        },
      },
    },
  );

  const navigation = navigationData?.getNavigation;
  const headerNavigation = navigation?.find(
    (nav) => nav.navigation_type === NavigationType.LogedOut,
  );
  const footerNavigation = navigation?.find((nav) => nav.navigation_type === footerNavigationType);

  const { data: coursesExistData, loading: coursesExistDataLoading } =
    useQuery<Pick<Query, 'listCoursesLogedOut'>>(GET_COURSES_EXIST_INFO);

  const {
    data: coursesProgressData,
    loading: coursesProgressDataLoading,
    refetch: refetchCourseProgressData,
  } = useQuery<Pick<Query, 'listCoursesLogedIn'>>(GET_COURSES_PROGRESS_INFO, {
    skip: !isAuthenticated,
  });

  const coursesProgress = coursesProgressData?.listCoursesLogedIn || [];

  const hasAnotherCourses = useMemo(
    () =>
      Boolean(
        coursesExistData?.listCoursesLogedOut && coursesExistData?.listCoursesLogedOut?.length > 1,
      ),
    [coursesExistData],
  );

  const dropdownCourses: CourseDropdownItem[] = useMemo(() => {
    const coursesList = coursesExistData?.listCoursesLogedOut;
    const coursesProgress = coursesProgressData?.listCoursesLogedIn;

    if (coursesList) {
      return coursesList.map((item) => ({
        id: item?.id,
        name: item?.name,
        slug: item?.slug,
        navigationDescription: item?.navigation_description,
        isStarted: Boolean(
          coursesProgress?.find(
            (progressItem) =>
              item?.id === progressItem?.id &&
              progressItem?.status &&
              progressItem.status !== CourseStatus.NotStarted,
          ),
        ),
      }));
    }

    return [];
  }, [coursesExistData, coursesProgressData]);

  useEffect(() => {
    if (userInfo) {
      changeUserId(userInfo?.id, userInfo?.email || '', getUserComUserId(userInfo?.id));
    }
  }, [userInfo, changeUserId]);

  return (
    <>
      <Header
        navigation={headerNavigation}
        assignedLearningsName={assignedLearningsName}
        logoUrl={logoUrl}
        dropdownCourses={dropdownCourses}
        isHeaderHidden={isHeaderHidden}
        onlyLogo={withoutNavigation}
      />
      <Root>
        <Routes>
          <Route
            path="/login/:ssoKey"
            element={<SsoLoginPage userInfo={userInfo} userLoading={userLoading} />}
          />
          {loginRoutes.map((route, index) => (
            <Route
              key={index}
              path={route}
              element={<LoginPage userInfo={userInfo} userLoading={userLoading} />}
            />
          ))}
          <Route path="/logout" element={<LogoutPage loadingUser={userLoading} />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/forgot-password" element={<ResetPasswordPage />} />
          {hasNews && (
            <>
              <Route
                path="/news/:id"
                element={
                  <SsrRedirect
                    to={(params) =>
                      `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Insights}/${params.id}`
                    }
                  />
                }
              />
              <Route
                path={`${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Insights}/:slug`}
                element={
                  <PageContainer>
                    <Suspense fallback={<Loader />}>
                      <NewsPage />
                    </Suspense>
                  </PageContainer>
                }
              />
              <Route
                path="/news"
                element={
                  <PageContainer>
                    <Suspense fallback={<Loader />}>
                      <NewsList />
                    </Suspense>
                  </PageContainer>
                }
              />
            </>
          )}
          <Route
            path="/pricing"
            element={
              <PageContainer>
                <Suspense fallback={<Loader />}>
                  <PricePage />
                </Suspense>
              </PageContainer>
            }
          ></Route>
          <Route
            path="/payment-successful"
            element={
              <RequireAuth withSubscription={false}>
                <PageContainer>
                  <Suspense fallback={<Loader />}>
                    <ThankYouPage tenantLinkedInId={linkedInId} />
                  </Suspense>
                </PageContainer>
              </RequireAuth>
            }
          />
          <Route
            path="/analytics/*"
            element={
              <PageContainer>
                <Suspense fallback={<Loader />}>
                  <AnalyticsPage
                    userInfo={userInfo}
                    userLoading={userLoading}
                    tenantTitle={tenantTitle}
                    tenantName={tenantName}
                  />
                </Suspense>
              </PageContainer>
            }
          />
          {!isSsku && (
            <Route
              path="/assigned-learning/*"
              element={
                <RequireAuth>
                  <PageContainer>
                    <AssignedLearningPage
                      tenantTitle={tenantTitle}
                      title={assignedLearningsName}
                      userInfo={userInfo}
                    />
                  </PageContainer>
                </RequireAuth>
              }
            />
          )}
          {homepageRoutes.map((route, index) => (
            <Route
              key={index}
              path={route}
              element={
                <RequireAuth>
                  <PageContainer>
                    <Suspense fallback={<Loader />}>
                      <DashboardPage tenantTitle={tenantTitle} />
                    </Suspense>
                  </PageContainer>
                </RequireAuth>
              }
            />
          ))}
          <Route
            path="/videos/:slug"
            element={
              <PageContainer>
                <Suspense fallback={<Loader />}>
                  <SingleVideoPage
                    waitForUser={waitForUser}
                    bumperUrl={tenant?.video_bumper_url}
                    setIsHeaderHidden={setIsHeaderHidden}
                  />
                </Suspense>
              </PageContainer>
            }
          />
          <Route
            path={`${SHARE_COURSE_ROUTE}/:hash`}
            element={
              <PageContainer>
                <Suspense fallback={<Loader />}>
                  <CertificatePage />
                </Suspense>
              </PageContainer>
            }
          />
          <Route
            path={`${SHARE_PATHWAY_ROUTE}/:hash`}
            element={
              <PageContainer>
                <Suspense fallback={<Loader />}>
                  <CertificatePage />
                </Suspense>
              </PageContainer>
            }
          />
          <Route
            path={`${DEFAULT_DISCOVER_URL}/pathways/:slug/test/:testId`}
            element={
              <RequireAuth>
                <PageContainer>
                  <Suspense fallback={<Loader />}>
                    <TestPage
                      tenantTitle={tenantTitle}
                      tenantName={tenantName}
                      tenantId={tenantId}
                      userId={userInfo?.id}
                    />
                  </Suspense>
                </PageContainer>
              </RequireAuth>
            }
          />
          <Route
            path={CASE_STUDY_PATH}
            element={
              <RequireAuth>
                <PageContainer>
                  <Suspense fallback={<Loader />}>
                    <Modal>
                      <CaseStudyPage />
                    </Modal>
                  </Suspense>
                </PageContainer>
              </RequireAuth>
            }
          />
          <Route
            path="/pathway/:slug/test/:testId"
            element={
              <SsrRedirect
                to={(params) =>
                  `${DEFAULT_DISCOVER_URL}/pathways/${params?.slug}/test/${params?.testId}`
                }
              />
            }
          />
          <Route
            path={`${DEFAULT_DISCOVER_URL}/pathways/:slug`}
            element={
              <PageContainer>
                <Suspense fallback={<Loader />}>
                  <PathwayPage
                    tenantCircleUrl={tenantCircleUrl}
                    tenantTitle={tenantTitle}
                    tenantName={tenantName}
                    waitForUser={waitForUser}
                  />
                </Suspense>
              </PageContainer>
            }
          />
          <Route
            path="/pathway/:slug"
            element={
              <SsrRedirect to={(params) => `${DEFAULT_DISCOVER_URL}/pathways/${params?.slug}`} />
            }
          />
          <Route
            path={`${DEFAULT_DISCOVER_URL}/pathways/:slug/continue-watching`}
            element={<ContinueWatching />}
          />
          <Route
            path="/pathway/:slug/continue-watching"
            element={
              <SsrRedirect
                to={(params) =>
                  `${DEFAULT_DISCOVER_URL}/pathways/${params?.slug}/continue-watching`
                }
              />
            }
          />
          <Route
            path="/share/courses/:hash"
            element={
              <Suspense fallback={<Loader />}>
                <CourseSharePage tenantTitle={tenantTitle} tenantName={tenantName} />
              </Suspense>
            }
          />
          <Route
            path="/share/assignments/:hash"
            element={
              <Suspense>
                <AslSharePage tenantName={tenantName} loadingTenant={loadingTenant} />
              </Suspense>
            }
          />
          <Route
            path="/share/:hash"
            element={
              <Suspense fallback={<Loader />}>
                <SharePage tenantTitle={tenantTitle} tenantName={tenantName} />
              </Suspense>
            }
          />
          <Route
            path={`${DEFAULT_DISCOVER_URL}/*`}
            element={
              <PageContainer>
                <Suspense fallback={<Loader />}>
                  <DiscoverPage
                    tenant={tenant}
                    loadingTenant={loadingTenant}
                    userInfo={userInfo}
                    userLoading={userLoading}
                  />
                </Suspense>
              </PageContainer>
            }
          />
          {oldExpertPageRoutes.map((route, index) => (
            <Route
              key={index}
              path={route}
              element={
                <SsrRedirect
                  to={(params) => `${DEFAULT_DISCOVER_URL}/experts/${params?.expertSlug || ''}`}
                />
              }
            />
          ))}
          <Route path="/experts" element={<SsrRedirect to={`${DEFAULT_DISCOVER_URL}/experts`} />} />
          <Route
            path="/pathways"
            element={<SsrRedirect to={`${DEFAULT_DISCOVER_URL}/pathways`} />}
          />
          <Route path="/videos" element={<SsrRedirect to={`${DEFAULT_DISCOVER_URL}/pathways`} />} />
          {tenant?.name === TenantsRefs.Fu &&
            hardcodedCourseUrls.map((route, index) => (
              <Route
                key={index}
                path={route}
                element={
                  <PageContainer>
                    <Suspense>
                      <CourseDetailsPageHardcoded
                        tenant={tenant}
                        coursesProgress={coursesProgress}
                        coursesProgressLoading={coursesProgressDataLoading}
                        hasAnotherCourses={hasAnotherCourses}
                        hasAnotherCoursesLoading={coursesExistDataLoading}
                        refetchCourseProgressData={refetchCourseProgressData}
                      />
                    </Suspense>
                  </PageContainer>
                }
              />
            ))}
          <Route
            path="/courses/:slug"
            element={
              <PageContainer>
                <Suspense>
                  <CourseDetailsPage
                    tenant={tenant}
                    coursesProgress={coursesProgress}
                    coursesProgressLoading={coursesProgressDataLoading}
                    hasAnotherCourses={hasAnotherCourses}
                    hasAnotherCoursesLoading={coursesExistDataLoading}
                    refetchCourseProgressData={refetchCourseProgressData}
                  />
                </Suspense>
              </PageContainer>
            }
          />
          <Route
            path="/courses/:slug/progress"
            element={
              <RequireAuth>
                <PageContainer>
                  <Suspense fallback={<Loader />}>
                    <CourseProgress
                      userInfo={userInfo}
                      tenant={tenant}
                      hasAnotherCourses={hasAnotherCourses}
                      hasAnotherCoursesLoading={coursesExistDataLoading}
                    />
                  </Suspense>
                </PageContainer>
              </RequireAuth>
            }
          />
          <Route
            path="/courses"
            element={
              <PageContainer>
                <Suspense>
                  <CoursesListPage tenant={tenant} />
                </Suspense>
              </PageContainer>
            }
          />
          <Route path="/404" element={<NotFoundPage />} />
          <Route
            path="/my-account/*"
            element={
              <RequireAuth withSubscription={false}>
                <PageContainer>
                  <Suspense fallback={<Loader />}>
                    <MyAccountPage userInfo={userInfo} authAppId={authAppId} />
                  </Suspense>
                </PageContainer>
              </RequireAuth>
            }
          />
          <Route
            path="/"
            element={
              <PageContainer>
                <Homepage userInfo={userInfo} userLoading={userLoading} />
              </PageContainer>
            }
          />
          <Route path="/*" element={<CustomPageLayout userInfo={userInfo} tenant={tenant} />} />
        </Routes>
      </Root>
      {!withoutNavigation && (
        <Footer
          footerPartners={footerPartners}
          navigation={footerNavigation}
          tenantTitle={tenantTitle}
          linkedinLink={tenant?.linkedin_link}
          twitterLink={tenant?.twitter_link}
          facebookLink={tenant?.facebook_link}
          instagramLink={tenant?.instagram_link}
        />
      )}
    </>
  );
};

export default Layout;

const Root = styled('main')`
  flex-grow: 1;
`;

const PageContainer = styled('div')`
  min-height: 100vh;
`;
