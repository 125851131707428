import React, { FC, useContext } from 'react';

import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';

import HandsetIcon from '../../../assets/icons/HandsetIcon';
import MoneyIcon from '../../../assets/icons/MoneyIcon';
import PlaySmallIcon from '../../../assets/icons/PlaySmallIcon';
import SafeLink from '../../../components/SafeLink';
import { CustomButton } from '../../../components/UI/CustomButton';
import { SecondaryButton } from '../../../components/UI/SecondaryButton';
import { BodyText } from '../../../components/UI/Texts';
import { TenantCtx } from '../../../context/TenantProvider';
import useIsSsku from '../../../hooks/tenant/useIsSsku';
import useAuth from '../../../hooks/useAuth';
import useTenantTranslation from '../../../hooks/useTenantTranslation';
import Modal, { Modals } from '../../../modals/Modal';
import { LOGIN_ROUTE, PRICING_ROUTE } from '../../../routes';
import styled from '../../../styled';
import {
  BIG_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
  TINY_MARGIN_PX,
} from '../../../theme';
import { checkIsIframed } from '../../../utils/checkIsIframed';

interface LogoutMegaMenuFooterProps {
  className?: string;
  mainBtnProps?: { onClick: (e: any) => void; to: string } | { to: any };
  mainBtnText?: string;
  additionalBtnLink?: string;
  additionalBtnText?: string;
}

const LogoutMegaMenuFooter: FC<LogoutMegaMenuFooterProps> = ({
  className = '',
  mainBtnProps,
  mainBtnText,
  additionalBtnLink,
  additionalBtnText,
}) => {
  const { t } = useTenantTranslation();
  const { loginWithRedirect, loginWithPopup } = useAuth();
  const { tenant, config } = useContext(TenantCtx);
  const theme = useTheme();
  const navigate = useNavigate();
  const isSsku = useIsSsku();

  const handleJoinNow = () => {
    if (checkIsIframed()) {
      return loginWithPopup({ mode: 'signUp' });
    }

    if (isSsku) {
      return navigate(LOGIN_ROUTE);
    }

    loginWithRedirect({ mode: 'signUp' });
  };

  return (
    <Wrapper className={className} data-testid="magemenuLogoutFooter">
      <LinksSection>
        {tenant?.introduction_video_url && (
          <Modal url={tenant?.introduction_video_url} bumperUrl={null}>
            {({ openModal }) => (
              <ButtonItem
                data-testid="magemenuLogoutFooterIntroBtn"
                onClick={() => openModal(Modals.VideoModal)}
              >
                <IconWrapper>
                  <PlaySmallIcon color={theme.palette.common.violet} />
                </IconWrapper>
                <ItemText>{t('header.watchIntroductionVideo')}</ItemText>
              </ButtonItem>
            )}
          </Modal>
        )}
        {tenant?.book_a_demo_page_link && (
          <LinkItem data-testid="magemenuLogoutFooterDemoLink" to={tenant?.book_a_demo_page_link}>
            <IconWrapper>
              <HandsetIcon color={theme.palette.common.violet} />
            </IconWrapper>
            <ItemText>{t('header.bookDemo')}</ItemText>
          </LinkItem>
        )}
        {config.hasPricingPlans && (
          <LinkItem data-testid="magemenuLogoutFooterPricingLink" to={PRICING_ROUTE}>
            <IconWrapper>
              <MoneyIcon color={theme.palette.common.violet} />
            </IconWrapper>
            <ItemText>{t('header.pricing')}</ItemText>
          </LinkItem>
        )}
      </LinksSection>
      <ButtonsSection>
        <Title>{t('header.readyToGetStarted')}</Title>
        <ButtonsWrapper>
          <StyledCustomButton data-testid="magemenuLogoutFooterJoinBtn" onClick={handleJoinNow}>
            {t('button.joinNow')}
          </StyledCustomButton>
          {Boolean(mainBtnProps && mainBtnText) && (
            <BtnLink {...mainBtnProps}>
              <CustomButton>{mainBtnText}</CustomButton>
            </BtnLink>
          )}
          {Boolean(additionalBtnLink && additionalBtnText) && (
            <BtnLink to={additionalBtnLink}>
              <SecondaryButton>{additionalBtnText}</SecondaryButton>
            </BtnLink>
          )}
        </ButtonsWrapper>
      </ButtonsSection>
    </Wrapper>
  );
};

export default LogoutMegaMenuFooter;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding: ${SUBMIDDLE_MARGIN_PX} 9%;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid ${({ theme }) => theme.palette.common.violetAnalyticsCard};
  }

  @media (min-width: 1440px) {
    padding: ${SUBMIDDLE_MARGIN_PX} 3% ${SUBMIDDLE_MARGIN_PX} 5%;
  }
  @media (min-width: 1660px) {
    padding: ${SUBMIDDLE_MARGIN_PX} 5% ${SUBMIDDLE_MARGIN_PX} 7%;
  }
`;

const LinksSection = styled('div')`
  display: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    justify-content: space-between;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    justify-content: flex-start;

    & > * {
      margin-left: ${BIG_MARGIN_PX};

      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

const ButtonsSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: ${SMALL_MARGIN_PX};
    margin-bottom: ${MIDDLE_MARGIN_PX};
    border-bottom: 1px solid ${({ theme }) => alpha(theme.palette.common.white, 0.33)};
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    justify-content: flex-end;
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
    display: none;
  }

  @media (min-width: 1440px) {
    display: flex;
  }
`;

const Title = styled(BodyText)`
  display: none;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: inline;
    text-align: left;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    margin-right: ${SUBMIDDLE_MARGIN_PX};
  }
`;

const IconWrapper = styled('div')`
  margin-right: ${TINY_MARGIN_PX};
  display: flex;
  align-items: center;

  & svg {
    width: 20px;
    height: 20px;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    & svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const LinkItem = styled(Link)`
  display: flex;
  align-items: center;

  &:hover > p {
    color: ${({ theme }) => theme.palette.common.violet};
  }
`;

const ButtonItem = styled('button')`
  display: flex;
  align-items: center;
  background: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;

  &:hover > p {
    color: ${({ theme }) => theme.palette.common.violet};
  }
`;

const ItemText = styled(BodyText)`
  color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 600px;
  }
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  margin: -${SMALL_MARGIN_PX} -${TINY_MARGIN_PX} 0 -${TINY_MARGIN_PX};
  flex-wrap: wrap;

  & > * {
    margin: ${SMALL_MARGIN_PX} ${TINY_MARGIN_PX} 0 ${TINY_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 0;

    & > * {
      margin: 0;
    }
  }
`;

const BtnLink = styled(SafeLink)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

const StyledCustomButton = styled(CustomButton)`
  display: none;
  margin: ${SMALL_MARGIN_PX} ${TINY_MARGIN_PX} 0 ${TINY_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 0;
    display: inline-flex;
  }
`;
