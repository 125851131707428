import { gql } from '@apollo/client';

export const GET_COURSES_EXIST_INFO = gql`
  query GetCoursesExistInfo {
    listCoursesLogedOut {
      id
      name
      navigation_description
      slug
    }
  }
`;

export const GET_COURSES_PROGRESS_INFO = gql`
  query GetCoursesProgressInfo {
    listCoursesLogedIn {
      id
      status
    }
  }
`;

export const GET_LIST_COURSES_LOGGED_OUT = gql`
  query GetListCoursesLoggedOut {
    listCoursesLogedOut {
      id
      name
      slug
      description
      image_url
      svg_icon
      publish_status
      general_info {
        pathways
        learning_hours
        cpd_credits
        experts
      }
    }
  }
`;

export const GET_LIST_COURSES_LOGGED_IN = gql`
  query GetListCoursesLoggedIn($courseIds: [ID]) {
    listCoursesLogedIn(courseIds: $courseIds) {
      id
      status
      name
      slug
      hash
      description
      image_url
      svg_icon
      publish_status
      general_info {
        pathways
        learning_hours
        cpd_credits
        experts
      }
      progress
    }
  }
`;
export const GET_CERTIFICATE_COURSE_BY_HASH = gql`
  query GetCourseCertificateByHash($hash: String!) {
    getCourseStatusByHash(hash: $hash) {
      learner {
        id
        first_name
        last_name
      }
      course {
        hash
        name
        slug
        svg_icon
        image_url
        general_info {
          cpd_credits
          learning_hours
          videos
          pathways
        }
        pathways {
          id
          name
          description
          image_url
          duration
          count_videos
          cpd_credits
          quizes {
            id
          }
          available_quizes {
            id
          }
          videos {
            video_url
          }
        }
        key_learning_objectives {
          id
          title
        }
        certificate_id
        completion_date
        about_course
        experts {
          avatar
          name
          job_title
          description
        }
      }
    }
  }
`;

export const GET_COURSE = gql`
  query GetCourse($filter: CourseSearchFilter) {
    getCourse(filter: $filter) {
      id
      name
      slug
      description
      image_url
      foreground_image_seo_name
      foreground_image_url
      svg_icon
      intro_verse_video_id
      general_info {
        pathways
        learning_hours
        cpd_credits
        videos
        experts
      }
      usp_blocks {
        image
        label_text
      }
      videos {
        id
        verse_video_id
      }
      experts {
        id
        name
        avatar
        job_title
        slug
        description
      }
      pathways {
        id
        name
        description
        slug
        image_url
        duration
        count_videos
        cpd_credits
        intro_verse_story_id
        difficulty {
          id
          icon_url
          title
        }
        categories {
          id
          title
          slug
          parent {
            id
            slug
          }
        }
        difficulty {
          id
          title
        }
        quizes {
          id
        }
        available_quizes {
          id
        }
        videos {
          video_url
        }
      }
      about_course
      key_learning_objectives {
        id
        title
      }
      who_is_this_for
      requirements
      experts {
        id
        avatar
        job_title
        name
        slug
      }
      partners {
        alt
        image_url
      }
      tenants {
        id
        name
      }
      status
    }
  }
`;

export const GET_COURSE_PROGRESS = gql`
  query GetCourseProgress($filter: CourseSearchFilter) {
    getCourse(filter: $filter) {
      # hash work only for authenticated user
      hash
      progress
      status
    }
  }
`;

export const START_COURSE = gql`
  mutation startCourse($course_id: Int!) {
    startCourse(course_id: $course_id)
  }
`;

export const GET_COURSE_PATHWAYS_PROGRESS = gql`
  query GetCoursePathwaysProgress($filter: CourseSearchFilter) {
    getUserCoursePathwaysProgressStat(filter: $filter) {
      cpd_credits_stat {
        completed
        total
      }
      pathways_stat {
        completed
        total
      }
      pathways_progresses_stat {
        progress
        pathway_videos_count
        pathway_watched_videos_count
        test_score
        status
        pathway {
          id
        }
      }
    }
  }
`;

export const GET_COURSE_DATA_BY_HASH = gql`
  query GetCourseDataByHash($hash: String!) {
    getCourseStatusByHash(hash: $hash) {
      course {
        name
        description
        slug
      }
    }
  }
`;

export const GET_COURSE_COMPLETION_BY_HASH = gql`
  query GetCourseCompletionByHash($hash: String!) {
    getCourseStatusByHash(hash: $hash) {
      completed_at
    }
  }
`;

export const GET_NEXT_PATHWAY_IN_COURSE = gql`
  query GetNextPathwayInCourse($filter: PathwaySearchFilter) {
    getNextPathwayInCourse(filter: $filter) {
      pathway {
        id
        name
        description
        count_videos
        cpd_credits
        duration
        slug
        videos {
          user {
            id
          }
        }
        image_url
        categories {
          id
          title
          slug
          parent {
            slug
            id
          }
        }
        difficulty {
          id
          icon_url
          title
        }
      }
      doesBelongToCourse
      currentCourse {
        slug
        name
        svg_icon
      }
    }
  }
`;

export const GET_COURSE_AFTER_CURRENT = gql`
  query GetNextCourseAfterCurrent {
    getNextCourseAfterCurrentOne {
      description
      svg_icon
      image_url
      name
      general_info {
        pathways
        learning_hours
        cpd_credits
        videos
        experts
      }
    }
  }
`;
