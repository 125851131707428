import React, { FC, useContext } from 'react';

import { SerializedStyles } from '@emotion/react';
import { Theme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import useReview from '../../../../blocks/Reviews/useReview';
import { DEFAULT_DISCOVER_URL } from '../../../../const';
import { TenantCtx } from '../../../../context/TenantProvider';
import useDownloadFile from '../../../../hooks/useDownloadFile';
import useTenantTranslation from '../../../../hooks/useTenantTranslation';
import { generateImageKitUrl } from '../../../../modules/imageKit';
import { DiscoverPathwaysPassedQuizInfo, Maybe } from '../../../../types';
import { calculatePathwayStatus, PATHWAY_ACTIONS } from '../../../../utils/calculatePathwayStatus';
import { generateCertificateName, getCertificatePDFUrl } from '../../../../utils/certificateUtils';
import { CustomButton } from '../../../UI/CustomButton';
import CardTestResults from '../CardTestResults/CardTestResults';
import { StyledButton, StyledPlaySmallIcon, StyledRepeatIcon, Wrapper } from './styles';

interface CardPathwayActionBlockProps {
  pathwayId?: string | null;
  pathwaySlug?: string | null;
  testId?: number | null;
  hash?: string | null;
  totalVideos: number;
  watchedVideos: number;
  passDate?: string | null;
  pathwayName?: string | null;
  wrapperCss?: SerializedStyles | (({ theme }: { theme: Theme }) => SerializedStyles);
  cpdCredits?: string | null;
  category?: string | null;
  passedQuizes?: Maybe<Maybe<DiscoverPathwaysPassedQuizInfo>[]> | undefined;
}

const CardPathwayActionBlock: FC<CardPathwayActionBlockProps> = ({
  pathwaySlug,
  testId,
  hash,
  totalVideos,
  watchedVideos,
  passDate,
  pathwayName,
  wrapperCss,
  cpdCredits,
  category,
  pathwayId,
  passedQuizes,
}) => {
  const { t } = useTenantTranslation();
  const { status: reviewStatus } = useReview({ id: pathwayId });
  const isMaxStars = passedQuizes?.some((quiz) => quiz?.stars === '3');
  const status = calculatePathwayStatus({
    watchedVideos,
    totalVideos,
    hash,
    testId,
    isMaxStars,
    reviewStatus,
  });
  const { download, loading: loadingDownloadCertificate } = useDownloadFile();
  const { tenant } = useContext(TenantCtx);
  const tenantTitle = tenant?.title;
  const tenantName = tenant?.name;
  const certName = generateCertificateName(tenantTitle, pathwayName);
  const certUrl = generateImageKitUrl({
    path: getCertificatePDFUrl(hash, tenantName),
    transformation: undefined,
  });

  const linkedinModalProps = {
    entityName: pathwayName,
    passDate: passDate,
    resultHash: hash,
  };

  const shareModalProps = {
    resultHash: hash,
    pathwayName: pathwayName,
    cpdCredits: cpdCredits,
    category: category,
  };

  if (status === PATHWAY_ACTIONS.NOTHING) {
    return null;
  }

  return (
    <Wrapper optionalStyles={wrapperCss}>
      {status === PATHWAY_ACTIONS.WATCH_NEW_CONTENT || status === PATHWAY_ACTIONS.PASSED_TEST ? (
        <CardTestResults
          linkedinModalProps={linkedinModalProps}
          shareModalProps={shareModalProps}
          handleCertDownload={() => !loadingDownloadCertificate && download(certName, certUrl)}
          certDownloading={loadingDownloadCertificate}
        />
      ) : (
        <div />
      )}
      {status === PATHWAY_ACTIONS.LEAVE_REVIEW && (
        <Link
          data-testid="pathwayLink"
          to={`${DEFAULT_DISCOVER_URL}/pathways/${pathwaySlug}#review-form`}
        >
          <StyledButton data-testid="continueWatchingBtn">
            {t('page.pathway.leaveReview')}
          </StyledButton>
        </Link>
      )}
      {status === PATHWAY_ACTIONS.CONTINUE_WATCHING && (
        <Link
          data-testid="pathwayLink"
          to={`${DEFAULT_DISCOVER_URL}/pathways/${pathwaySlug}/continue-watching`}
        >
          <StyledButton data-testid="continueWatchingBtn">
            <StyledPlaySmallIcon data-testid="play-icon" className="playIcon" />
            {t('page.discover.continueWatching')}
          </StyledButton>
        </Link>
      )}
      {status === PATHWAY_ACTIONS.TAKE_TEST && (
        <Link
          data-testid="pathwayLink"
          to={`${DEFAULT_DISCOVER_URL}/pathways/${pathwaySlug}/test/${testId}`}
        >
          <CustomButton data-testid="takeTest">{t('page.discover.takeTest')}</CustomButton>
        </Link>
      )}
      {status === PATHWAY_ACTIONS.WATCH_NEW_CONTENT && (
        <Link data-testid="pathwayLink" to={`${DEFAULT_DISCOVER_URL}/pathways/${pathwaySlug}`}>
          <CustomButton data-testid="watchNewContent">
            <StyledPlaySmallIcon data-testid="play-icon" className="playIcon" />
            {t('page.discover.watchNewContent')}
          </CustomButton>
        </Link>
      )}
      {(status === PATHWAY_ACTIONS.PASSED_TEST || status === PATHWAY_ACTIONS.WATCH_AGAIN) && (
        <Link data-testid="pathwayLink" to={`${DEFAULT_DISCOVER_URL}/pathways/${pathwaySlug}`}>
          <CustomButton data-testid="rewatch">
            <StyledRepeatIcon data-testid="repeat-icon" className="repeatIcon" />
            {t('page.pathway.retakeTest')}
          </CustomButton>
        </Link>
      )}
    </Wrapper>
  );
};

export default CardPathwayActionBlock;
