import React, { useContext } from 'react';

import { PaletteMode } from '@mui/material';

import { TenantTheme } from '../theme';

interface ISsrContext {
  redirect?: (url: string) => void;
  tenantTheme: null | TenantTheme;
  tenantThemeMode: null | PaletteMode;
}

const SsrContext = React.createContext<ISsrContext>({
  redirect: undefined,
  tenantTheme: null,
  tenantThemeMode: null,
});

export const useSsrContext = () => useContext(SsrContext);

export default SsrContext;
